import Header from './Components/Header';
import Services from './Components/Services';
import './app.css';
import Footer from './Components/Footer';
import ScrollArrow from './Components/ScrollArrow';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import Therapeutics from './Components/Therapeutics'; // Import Therapeutics

function App() {
  return (
    <div className="App font-link">
      <Header id="home" />
      <ScrollArrow />
      <AboutUs id="aboutus" />
      <Services id="services" />
      <Therapeutics id="therapeutics" /> {/* Add Therapeutics component */}
      <ContactUs id="contactus" />
      <Footer />
    </div>
  );
}

export default App;