import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { HashLink } from "react-router-hash-link"; // Import HashLink from react-router-hash-link
import { ClipLoader } from "react-spinners";

const logoUrl = "https://nexuamedia.s3.amazonaws.com/nexus_logo_blck.png"; // Replace with your S3 logo URL

const navigation = [
  { name: "Home", href: "#home", current: true },
  { name: "About Us", href: "#aboutus", current: false },
  { name: "Our Services", href: "#services", current: false },
  { name: "Therapeutics", href: "#therapeutics", current: false }, 
  { name: "Contact Us", href: "#contactus", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ className }) {
  const [navs, setNavs] = useState(navigation);
  const [logoLoading, setLogoLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const offset = 100;
      const updatedNavs = navs.map((item) => ({
        ...item,
        current: item.href === "#home" ? scrollPosition < offset : window.location.hash === item.href,
      }));
      setNavs(updatedNavs);
    };

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [navs]);

  const handleActive = (nav) => {
    setNavs(
      navs.map((item) => ({
        ...item,
        current: item.name === nav.name,
      }))
    );
  };

  const handleClick = (nav) => {
    setLogoLoading(true);

    setTimeout(() => {
      setLogoLoading(false);
      handleActive(nav);
    }, 1000);
  };

  return (
    <Disclosure as="nav" className={`bg-white ${className} fixed top-0 inset-x-0 z-50`}>
      {({ open }) => (
        <>
          <div className="relative z-10 max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-20">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center">
                {logoLoading ? (
                  <ClipLoader size={50} color="#1C9290" />
                ) : (
                  <HashLink to="#home" smooth>
                    <img
                      src={logoUrl}
                      alt="Nexus Research Services"
                      className="h-20 w-auto"
                    />
                  </HashLink>
                )}
              </div>

              {/* Navigation links */}
              <div className="hidden sm:flex sm:items-center sm:justify-end sm:flex-1">
                <div className="flex space-x-4">
                  {navs.map((item) => (
                    <HashLink
                      key={item.name}
                      smooth
                      to={item.href}
                      className={classNames(
                        "px-3 py-2 rounded-md text-sm font-bold md:text-xs lg:text-base",
                        item.current
                          ? "text-[#E16A3D] bg-clip-text"
                          : "text-[#1C9290] hover:text-[#E16A3D] hover:bg-transparent"
                      )}
                      onClick={() => handleClick(item)}
                      onMouseEnter={() => handleActive(item)}
                      onFocus={() => handleActive(item)}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </HashLink>
                  ))}
                </div>
              </div>

              {/* Mobile menu button */}
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-[#1C9290] hover:text-[#E16A3D] hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 text-right">
              {navs.map((item) => (
                <HashLink
                  key={item.name}
                  smooth
                  to={item.href}
                  className={classNames(
                    "block px-3 py-2 rounded-md text-base font-bold text-right",
                    item.current
                      ? "bg-transparent text-[#E16A3D]"
                      : "text-[#1C9290] hover:bg-transparent hover:text-[#E16A3D]"
                  )}
                  onClick={() => handleClick(item)}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </HashLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}