import React from 'react';
import { Fade } from "react-awesome-reveal";
import { HashLink } from "react-router-hash-link";
import { AiOutlinePhone } from 'react-icons/ai';

function Footer() {
  const currentYear = new Date().getFullYear();

  const handlePhoneClick = () => {
    navigator.clipboard.writeText('+1234567890');
    alert('Phone number copied to clipboard!');
  };

  return (
    <div className="bg-[#043E52] py-8 px-6 lg:px-28">
      <Fade bottom cascade>
        <div className="flex flex-col lg:flex-row justify-between items-center">
          {/* Contact Section */}
          <div className="flex items-center gap-4 cursor-pointer" onClick={handlePhoneClick}>
            <AiOutlinePhone className="h-8 w-8 text-white bg-[#E16A3D] rounded-full p-2" />
            <div className="text-white">
              <h5 className="font-normal text-base lg:text-lg">Call Us: +1234567890</h5>
            </div>
          </div>

          {/* Footer Links */}
          <div className="flex flex-col lg:flex-row lg:items-center text-center gap-4 mt-4 lg:mt-0">
            <div className="flex flex-col lg:flex-row lg:items-center text-[#E16A3D] text-base lg:text-lg gap-4">
              <HashLink smooth to="#aboutus" className="hover:text-white transition duration-300">About Us</HashLink>
              <HashLink smooth to="#contactus" className="hover:text-white transition duration-300">Contact</HashLink>
              <HashLink smooth to="#services" className="hover:text-white transition duration-300">Services</HashLink>
            </div>
            <div className="text-[#E16A3D] text-base lg:text-lg mt-4 lg:mt-0">
              <HashLink smooth to="#home" className="hover:text-white transition duration-300">
                &copy; {currentYear} Nexus Research Services
              </HashLink>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Footer;
