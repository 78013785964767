import React, { useState } from "react";
import { Slide } from "react-awesome-reveal";

const servicesImage = "https://nexuamedia.s3.amazonaws.com/services.png";

function ContactCard() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [showEmailPad, setShowEmailPad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const { name, email, message } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name.length < 2 || name.length > 100) {
      return "Name must be between 2 and 100 characters.";
    }
    if (!emailRegex.test(email)) {
      return "Invalid email format.";
    }
    if (message.length > 1000) {
      return "Message cannot exceed 1000 characters.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      setStatusMessage(error);
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await fetch(
        "https://yoyjpv76s2.execute-api.us-east-1.amazonaws.com/prod/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      const result = await response.json();
      if (response.ok) {
        setStatusMessage("Emails sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",  // Ensure phone is cleared
          message: "",
        });
        setTimeout(() => {
          setStatusMessage("");
        }, 3000);
      } else {
        setStatusMessage(`Failed to send email: ${result.message}`);
      }
    } catch (error) {
      setStatusMessage("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-[#043E52] pt-14 lg:px-28 px-10 pb-10 min-h-screen flex flex-col justify-center items-center" id="contactus">
      <div className="w-10 h-1 from-[#E16A3D] to-[#FFA45D] bg-gradient-to-r mb-6"></div>
      <h1 className="text-white text-4xl font-semibold mb-6">Contact Us</h1>
      <div className="flex flex-col lg:flex-row max-w-7xl w-full bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="flex-none w-full lg:w-1/2 relative">
          <img src={servicesImage} alt="Services" className="w-full h-full object-cover transition-transform duration-500 transform hover:scale-105" />
        </div>
        <div className="flex flex-col justify-center w-full lg:w-1/2 p-6">
          <Slide direction="right" cascade>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-800">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 rounded-md bg-gray-100 text-gray-800"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-800">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 rounded-md bg-gray-100 text-gray-800"
                  required
                  onFocus={() => setShowEmailPad(true)}
                />
              </div>
              {showEmailPad && (
                <div className="animate-slideRight">
                  {/* Email pad content here */}
                </div>
              )}
              <div>
                <label className="block text-gray-800">Phone (optional)</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 rounded-md bg-gray-100 text-gray-800"
                />
              </div>
              <div>
                <label className="block text-gray-800">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-2 mt-1 rounded-md bg-gray-100 text-gray-800"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full p-2 mt-4 rounded-md transition-colors duration-300 transform hover:-translate-y-1 bg-[#1C9290] text-white hover:bg-[#FFA45D]"
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
              {statusMessage && (
                <p className="mt-4 text-red-600">{statusMessage}</p>
              )}
            </form>
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default ContactCard;