import React from 'react';
import { Slide } from 'react-awesome-reveal';
import { HashLink } from 'react-router-hash-link';

const Therapeutics = () => {
  // Define keywords to highlight
  const keywords = [
    'Oncology',
    'Hematology',
    'Dermatology',
    'Neurology',
    'Neuroscience',
    'Infectious Diseases',
    'Rare Diseases',
    'Immunology',
    'Gastroenterology',
    'Endocrinology',
    'Cardiovascular',
    'Metabolic Disorders',
    'healthcare enhancement programs',
    'mental health initiatives',
    'global health security',
    'maternal and child health',
    'vaccine development',
    'medical device innovation',
    'clinical research',
    'medical specialties',
    'researchers',
    'pharmaceutical companies',
    'healthcare providers'
  ];

  // Function to highlight keywords in text
  const highlightKeywords = (text) => {
    // Replace occurrences of #043E52 with #FFA45D in the content text
    let updatedText = text.replace(/#043E52/g, '#FFA45D');

    // Create a regular expression to match keywords
    const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
    // Split text by keywords and non-keywords
    const parts = updatedText.split(regex);
    // Initialize keyCounter for unique keys
    let keyCounter = 0;
    // Map parts, apply styles to keywords
    return parts.map((part) => {
      if (keywords.includes(part.trim().toLowerCase())) {
        return (
          <span key={keyCounter++} className="text-[#E16A3D]">
            {part}
          </span>
        );
      } else {
        return (
          <span key={keyCounter++} className="text-[#043E52]">
            {part}
          </span>
        );
      }
    });
  };

  return (
    <section id="therapeutics" className="w-full mx-auto bg-white">
      {/* Button Section */}
      <div className="bg-[#043E52] pt-14 pb-10 flex flex-col justify-center items-center">
        <div className="w-10 h-1 mb-6" style={{ background: `linear-gradient(to right, #E16A3D, #FFA45D)` }}></div>
        <HashLink smooth to="#therapeutics">
          <h1 className="text-white text-4xl font-semibold mb-6 cursor-pointer hover:text-[#E16A3D] transition-colors duration-300">Therapeutics</h1>
        </HashLink>
      </div>

      {/* Left Section */}
      <Slide direction="left">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center lg:gap-4 xs:gap-2 pt-14 lg:px-28 px-10 pb-10">
          <div className="lg:w-[50%] xs:w-full h-full flex items-center order-2 lg:order-1">
            <img id="therapeutics-image" className="w-full h-auto lg:rounded-t-lg sm:rounded-sm xs:rounded-sm hover:scale-105 transition-transform duration-300" src="https://nexuamedia.s3.amazonaws.com/colors/vecteezy_person-touching-the-tube-of-in-the-style-of-dark-red-and_31531722.jpg" alt="Therapeutics Image" />
          </div>
          <div className="lg:w-[50%] xs:w-full bg-white p-6 rounded-md shadow-md flex items-center lg:order-2">
            <p className="text-lg text-justify mt-4">
              {highlightKeywords(`
                Therapeutic areas have revolutionized healthcare, offering groundbreaking interventions that elevate health knowledge and boost patient outcomes. Our team excels in a broad spectrum of therapeutic fields, such as Oncology and Hematology, Dermatology, Neurology, Neuroscience, Infectious Diseases, Rare Diseases, Immunology, Gastroenterology, Endocrinology, Cardiovascular, and Metabolic Disorders. Additionally, we are deeply engaged in healthcare enhancement programs, mental health initiatives, global health security, maternal and child health, vaccine development, and medical device innovation.
              `)}
            </p>
          </div>
        </div>
      </Slide>

      {/* Right Section */}
      <Slide direction="right">
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center lg:gap-4 xs:gap-2 pt-14 lg:px-28 px-10 pb-10">
          <div className="lg:w-[50%] xs:w-full bg-white p-6 rounded-md shadow-md flex items-center">
            <p className="text-lg text-justify mt-4">
              {highlightKeywords(`
                At the forefront of Sub-Saharan and global clinical research, our seasoned team leverages deep therapeutic expertise to pioneer advancements across a spectrum of medical specialties. Committed to excellence through innovation and collaboration, we join forces with researchers, pharmaceutical companies, and healthcare providers, propelling medical research to new heights.
              `)}
            </p>
          </div>
          <div className="lg:w-[50%] xs:w-full h-full flex items-center order-1 lg:order-2">
            <img className="w-full h-auto lg:rounded-t-lg sm:rounded-sm xs:rounded-sm hover:scale-105 transition-transform duration-300" src="https://nexuamedia.s3.amazonaws.com/colors/vecteezy_hand-put-wooden-cubes-with-insurance-health-care-medical_10111254.jpg" alt="Therapeutics Image" />
          </div>
        </div>
      </Slide>
    </section>
  );
};

export default Therapeutics;
