import React, { useState } from "react";
import { Slide } from "react-awesome-reveal";

const mediaUrls = [
  "https://nexuamedia.s3.amazonaws.com/colors/vecteezy_scientist-holding-flask-with-liquid-in-laboratory-science_32475233.jpg",
  "https://nexuamedia.s3.amazonaws.com/medical_monitoring.png",
  "https://nexuamedia.s3.amazonaws.com/patient+Advocacy.jpg",
  "https://nexuamedia.s3.amazonaws.com/Logistic.JPG",
  "https://nexuamedia.s3.amazonaws.com/Stake+holder+meeting.jpeg",
  "https://nexuamedia.s3.amazonaws.com/African+Travel+1.jpg",
];

const services = [
  {
    type: "Clinical Trials and Research Services",
    media: mediaUrls[0],
    content: `
      ▶ We provide oversight on the phases of clinical trials ranging from Phase I-IV Clinical Trials:
        • Comprehensive project management of all phases of clinical trials.
        • Regulatory submissions, compliance, and ethical standards adherence.
        • Data collection, clinical monitoring, and laboratory sample management and analysis.

      ▶ Market Authorizations and Access:
        • Submission and follow-up for regulatory approvals.
        • Market access strategies and health economics.
        • Post-marketing surveillance and lifecycle management.

      ▶ Site Activation and Management:
        • Site identification, qualification, and selection.
        • Negotiation and execution of site contracts.
        • Training and support for site staff.

      ▶ Site Payments:
        • Timely and accurate payment processing.
        • Financial tracking and reporting.
        • Resolution of payment issues.
    `,
  },
  {
    type: "Medical Affairs and Safety Consulting",
    media: mediaUrls[1],
    content: `
      ▶ Safety Reporting:
        • Serious Adverse Event (SAE) reporting and management.
        • Pharmacovigilance services.
        • Risk management and mitigation strategies.

      ▶ Medical Liaison:
        • Communication between clinical teams and healthcare professionals.
        • Scientific support and education.
        • Addressing medical inquiries and information dissemination.
    `,
  },
  {
    type: "Patient Recruitment and Advocacy",
    media: mediaUrls[2],
    content: `
      ▶ Recruitment Strategies:
        • Targeted recruitment campaigns.
        • Patient engagement and retention programs.
        • Utilization of digital and traditional media for outreach.

      ▶ Advocacy:
        • Patient support and education.
        • Collaboration with patient advocacy groups.
        • Addressing patient concerns and feedback.
    `,
  },
  {
    type: "Sub-Saharan Logistics/Clinical Supplies",
    media: mediaUrls[3],
    content: `
      ▶ Supply Chain Management:
        • Efficient distribution of clinical supplies.
        • Cold chain management for temperature-sensitive products.
        • Inventory management and replenishment.

      ▶ Regulatory Compliance:
        • Ensuring compliance with local regulations.
        • Import/export documentation and clearance.
        • Coordination with local authorities and stakeholders.
    `,
  },
  {
    type: "Key Opinion Leader and Stakeholder Meetings",
    media: mediaUrls[4],
    content: `
      ▶ Organization and Coordination:
        • Planning and execution of KOL meetings and advisory boards.
        • Facilitation of stakeholder engagements and collaborations.
        • Management of logistics and attendee coordination.

      ▶ Content Development:
        • Creation of meeting agendas and materials.
        • Moderation and facilitation of discussions.
        • Post-meeting reports and follow-up actions.
    `,
  },
  {
    type: "Africa Travel Management",
    media: mediaUrls[5],
    content: `
      ▶ Travel Planning and Coordination:
        • Arranging travel for clinical trial staff and participants.
        • Booking accommodations, transportation, and other logistics.
        • Handling travel-related issues and emergencies.

      ▶ Cultural and Regional Expertise:
        • Guidance on local customs and practices.
        • Ensuring safety and security during travel.
        • Support for visas and other travel documentations.
    `,
  },
];

function Services() {
  const [activeService, setActiveService] = useState(null);

  const handleServiceClick = (index) => {
    setActiveService(index);
  };

  const handleBackClick = () => {
    setActiveService(null);
  };

  return (
    <div className="bg-[#043E52] pt-14 lg:px-28 px-10 pb-10 min-h-screen flex flex-col justify-center items-center" id="services">
      <div className="w-10 h-1 from-[#E16A3D] to-[#FFA45D] bg-gradient-to-r mb-4"></div>
      <h1 className="text-white text-4xl font-semibold mb-6">Our Services</h1>
      
      {activeService === null ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full max-w-5xl">
          {services.map((service, index) => (
            <Slide key={index} direction="up">
              <div
                className="service-item bg-white p-4 rounded-lg shadow-lg cursor-pointer transition duration-300 transform hover:scale-105"
                onClick={() => handleServiceClick(index)}
              >
                <img src={service.media} alt={service.type} className="w-full h-48 object-cover rounded-md mb-4" />
                <h2 className="text-[#043E52] text-xl font-bold text-center mb-4">{service.type}</h2>
              </div>
            </Slide>
          ))}
        </div>
      ) : (
        <div className="service-detail bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl">
          <button className="text-[#016A6D] font-bold mb-4" onClick={handleBackClick}>
            &larr; Back to Services
          </button>
          <h2 className="text-[#1C9290] text-2xl font-bold mb-4">{services[activeService].type}</h2>
          <img src={services[activeService].media} alt={services[activeService].type} className="w-full h-48 object-cover rounded-md mb-4" />
          <div className="text-[#043E52] whitespace-pre-line">
            {services[activeService].content.trim().split('\n').map((line, index) => (
              <p key={index} className={line.includes("▶") ? "mt-4 font-semibold" : line.includes("•") ? "ml-6 list-disc" : "ml-10"}>
                {line}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
