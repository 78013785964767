import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Fade } from "react-awesome-reveal";

const landingMediaUrl = "https://nexuamedia.s3.amazonaws.com/colors/animation3.mp4";
const isVideo = landingMediaUrl.endsWith('.mp4');

function Header() {
  const [isHomePage, setIsHomePage] = useState(false);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const offset = 100;
      setIsHomePage(scrollPosition < offset);
    };

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToContact = () => {
    const contactSection = document.getElementById("contactus");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMediaLoad = () => {
    setIsMediaLoaded(true);
  };

  return (
    <div id="home" className={`relative w-full min-h-screen overflow-hidden bg-[#043E52] ${isHomePage ? 'home-page' : ''}`}>
      <Navbar className="z-50" />

      {isVideo ? (
        <video
          src={landingMediaUrl}
          className={`absolute top-0 left-0 w-full h-full object-cover ${isMediaLoaded ? 'block' : 'hidden'}`}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={handleMediaLoad}
          style={{ filter: 'brightness(50%)' }}
          playbackrate="0.8"
        />
      ) : (
        <img
          src={landingMediaUrl}
          alt="many people joining their hands to lift Africa up"
          className={`absolute top-0 left-0 w-full h-full object-cover ${isMediaLoaded ? 'block' : 'hidden'}`}
          onLoad={handleMediaLoad}
        />
      )}

      <div className="relative z-10 flex flex-col items-center justify-center w-full h-full bg-opacity-50 px-10 lg:px-28 text-white">
        <div className="w-full flex flex-col items-center justify-center text-center lg:text-left lg:pt-8">
          <Fade direction="left" cascade triggerOnce className="w-full lg:w-2/3">
            <div className="lg:w-2/3 w-full animate-fade-in-down">
              <h1 className="md:text-6xl text-3xl mb-2 font-bold text-white tracking-tight leading-tight">
                Advancing Health,
              </h1>
              <h4 className="md:text-6xl text-3xl mb-2 font-bold text-orange tracking-tight leading-tight">
                Bridging Worlds
              </h4>
              <div className="flex flex-col lg:flex-row items-center mt-7 w-full justify-center">
                <button
                  className="uppercase rounded bg-[#FFA45D] text-white relative group hover:text-white overflow-hidden c-btn tracking-wider mb-4 lg:mb-0 lg:mr-4"
                  onClick={scrollToContact}
                >
                  Work with us
                </button>
                <div className="bg-white text-[#043E52] p-4 rounded-lg lg:ml-4">
                  <p className="text-sm lg:text-base">
                    Elevating our communities through innovation, collaboration, and resilient healthcare solutions and services.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Header;
