import React, { useState, useEffect } from "react";
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is imported
import { Slide } from "react-awesome-reveal"; // Import Slide from react-awesome-reveal

const aboutUsContent = {
  aboutUs: (
    <div className="text-center" style={{ color: "#043E52" }}>
      <h2 className="text-2xl font-semibold mb-4">who we are</h2>
      <p text-lg="true" text-justify="true" mt-4="true">
        Nexus Research's is a Contract Research Organization (CRO) that has emerged from Sub-Saharan Africa, connecting various African countries with the global research landscape. Our company is dedicated to creating an ecosystem of diverse expertise, fostering collaboration and innovation. We aim to be the harbinger of novel, safe, quality, and efficacious therapies, providing healthcare solutions that optimize healthcare outcomes worldwide.
      </p>
    </div>
  ),
  visionStatement: (
    <div className="text-center" style={{ color: "#043E52" }}>
      <h2 className="text-2xl font-semibold mb-4"> vision </h2>
      <p text-lg="true" text-justify="true" mt-4="true">
        To be the premier contract research organization driving innovation and excellence in healthcare across Africa, fostering collaboration and creating sustainable solutions for a healthier future.
      </p>
    </div>
  ),
  missionStatement: (
    <div className="text-center" style={{ color: "#043E52" }}>
      <h2 className="text-2xl font-semibold mb-4"> mission </h2>
      <p text-lg="true" text-justify="true" mt-4="true">
        Nexus Research is dedicated to advancing healthcare in Africa by providing high-quality, ethical, and efficient contract research services. We strive to collaborate with local and international partners, leveraging our network across Sub-Saharan Africa to accelerate the development of life-saving treatments and therapies, ultimately improving the quality of life for people across the continent and the world at large.
      </p>
    </div>
  ),
};

const tabs = [
  { id: "aboutUs", label: "who we are" },
  { id: "visionStatement", label: "vision" },
  { id: "missionStatement", label: "mission " },
];

function AboutUs() {
  const [activeTab, setActiveTab] = useState("aboutUs");

  useEffect(() => {
    // Function to handle hash change
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Remove the leading '#'
      if (aboutUsContent[hash]) {
        setActiveTab(hash);
      }
    };

    // Check the initial hash on component mount
    handleHashChange();

    // Listen for hash change events
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    window.location.hash = tabId; // Set the URL hash
  };

  return (
    <div className="bg-[#043E52] pt-14 lg:px-28 px-10 pb-10 min-h-screen flex flex-col justify-center items-center" id="aboutus">
      <div className="w-10 h-1 mb-6" style={{ background: `linear-gradient(to right, #E16A3D, #FFA45D)` }}></div>
      <h1 className="text-white text-4xl font-semibold mb-6">About Us</h1>
      <div className="w-full flex justify-center space-x-2 mb-6">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            className={`px-4 py-2 font-semibold rounded-md cursor-pointer transition-colors duration-300 transform hover:-translate-y-1 ${
              activeTab === tab.id
                ? "bg-[#E16A3D] text-white hover:bg-opacity-90"
                : "bg-[#016A6D] text-white hover:text-[#FFA45D] hover:bg-[#1C9290]"
            }`}
            style={{ cursor: 'pointer' }} // Ensure cursor changes to pointer
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex justify-center w-full">
        <div className="bg-white rounded-lg p-6 w-full max-w-3xl shadow-md transition-transform duration-300 transform hover:-translate-y-1">
          <Slide direction="right">
            {aboutUsContent[activeTab]}
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;